import request from '@/utils/requestV2'
const qs = require('qs')

// 根据产品id获取媒介使用费的结算折扣,默认代理下的所有产品的最低折扣相同
export function getUsefeeSettlementDiscount (data) {
  return request({
    url: '/ooh-scp/v1/statementdiscountconfig/getusefeesettlementdiscount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取结算折扣设置详情
export function getProductDiscountList (data) {
  return request({
    url: '/ooh-scp/v1/statementdiscountconfig/getProductDiscountList',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 新增折扣设置
export function batchAddStatementDiscountConfig (data) {
  return request({
    url: '/ooh-scp/v1/statementdiscountconfig/batchAddStatementDiscountConfig',
    method: 'post',
    data: qs.stringify(data)
  })
}
