<template>
  <div>
    <Row>
      <i-col span="5">
        <Tree ref="companyTree" :data="tenantCompanyChannelList" @on-select-change="handleChangeCompany"></Tree>
      </i-col>

      <i-col span="12">
          <p class="m-t-10 m-b-20">
            默认全部产品最低折扣限价：
            <InputNumber size="small" :max="10" :min="0" v-model="defaultDiscount"></InputNumber> 折
            <Button size="small" type="success" class="m-l-20" @click="handleSubmit">保存全部设置</Button>
          </p>
          <p class="remark">注：如果下方表格内产品折扣，填“0”或者不填。 那么将产品默认产品最低折扣</p>

          <Table size="small" :columns="productColumns" :data="productData" stripe>
            <!-- 最低折扣设置 -->
            <template slot-scope="{ row, column, index }" slot="discount">
                <InputNumber size="small" :min="0" v-model="row.discount" @on-change="handleSetting(row, column, index)"></InputNumber>
                <span class="m-l-5">折</span>
            </template>
            <!-- 有效期开始时间 -->
            <template slot-scope="{ row, column, index }" slot="startDate">
                <DatePicker size="small" v-model="row.startDate" format="yyyy/MM/dd" type="date" placement="bottom-end"
                  transfer :clearable="false" style="width: 120px"  @on-change="handleSetting(row, column, index)"></DatePicker>
            </template>
            <!-- 有效期结束时间 -->
            <template slot-scope="{ row, column, index }" slot="endDate">
                <DatePicker size="small" v-model="row.endDate" format="yyyy/MM/dd" type="date" placement="bottom-end"
                  transfer :clearable="false" style="width: 120px"  @on-change="handleSetting(row, column, index)"></DatePicker>
            </template>
          </Table>
      </i-col>
    </Row>

    <Spin fix size="large" v-if="spinShow">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>保存设置中...</div>
    </Spin>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'

import { ParseDate } from '@/utils/dateFormat'
import { getCompanyChild } from '@/api/os/company'
import { getProductDiscountList } from '@/api/scp/statementdiscountconfig'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: this.$store.getters.token.userInfo.companyId,
      defaultDiscount: 0,

      tenantCompanyChannelList: [],
      chooseCompanyId: null,

      productColumns: [
        { title: '产品名称', key: 'productName' },
        { title: '最低折扣限价', slot: 'discount' },
        { title: '有效开始期', width: 150, slot: 'startDate' },
        { title: '有效结束期', width: 150, slot: 'endDate' }
      ],
      productData: [],

      spinShow: false
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData2 = {
        companyId: this.companyId,
        type: this.publisherId === this.companyId ? 1 : 2,
        childType: 2,
        enabled: true
      }

      getCompanyChild(postData2).then(res => {
        // 格式化代理商清单
        this.tenantCompanyChannelList = this.expandAllCompanyChild(res.children)
        if (this.tenantCompanyChannelList.length > 0) {
          this.handleChangeCompany([this.tenantCompanyChannelList[0]])
        }
      })
    },
    handleSetting (row, column, index) {
      if (column.slot === 'discount') {
        this.productData[index].discount = row.discount
      } else if (column.slot === 'startDate') {
        this.productData[index].startDate = ParseDate(row.startDate)
      } else if (column.slot === 'endDate') {
        this.productData[index].endDate = ParseDate(row.endDate)
      }
    },
    expandAllCompanyChild (childData) {
      childData.forEach(element => {
        element.expand = true
        element.title = element.companyName
        element.children = this.expandAllCompanyChild(element.children)
      })
      return childData
    },
    handleChangeCompany (node) {
      this.tenantCompanyChannelList = this.treeChangeSelected(this.tenantCompanyChannelList, node[0].id)
      this.chooseCompanyId = node[0].companyId

      // 开始载入公司数据
      const queryModel = {
        companyId: node[0].companyId,
        publisherId: this.publisherId
      }

      getProductDiscountList(queryModel).then(res => {
        this.productData = res.productDiscountList
        this.defaultDiscount = res.defaultDiscount
        this.spinShow = false
      })
    },
    treeChangeSelected (treeData, nodeId) {
      const _this = this
      for (var i = 0; i < treeData.length; i++) {
        this.$set(treeData[i], 'selected', false) // 重要！用set方法
        if (nodeId === treeData[i].id) { // 非当前选中设置为未选中
          this.$set(treeData[i], 'selected', true) // 重要！用set方法
        }

        if (treeData[i].children) {
          treeData[i].children = _this.treeChangeSelected(treeData[i].children, nodeId)
        }
      }

      return treeData
    },
    handleSubmit () {
      this.spinShow = true
      const postData = {
        companyId: this.chooseCompanyId,
        publisherId: this.publisherId,
        defaultDiscount: this.defaultDiscount,
        productDiscountList: this.productData
      }

      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/statementdiscountconfig/batchAddStatementDiscountConfig',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '保存设置成功！' })
        } else {
          this.$Notice.error({ desc: res.errmsg })
        }

        // 重新请求清单， 还原列表显示折扣
        const companyIndex = this.tenantCompanyChannelList.findIndex(x => x.companyId === this.chooseCompanyId)
        if (companyIndex > -1) {
          this.handleChangeCompany([this.tenantCompanyChannelList[companyIndex]])
        }
      }).catch(() => {
        this.spinShow = false
      })
    }
  }
}
</script>
